/** @jsx jsx */

import { Accordion } from 'src/components/Accordion/Accordion'
import ReactMarkdown from 'react-markdown'
import { Squidex_Vacancy } from 'src/types/gatsby-types'
import { jsx } from '@emotion/react'

type Props = {
  vacancy: Squidex_Vacancy
}

export const CareersItem: React.FC<Props> = ({ vacancy }) => {
  return (
    <Accordion
      title={`${vacancy.flatData.title} (${vacancy.flatData.location})`}
    >
      <ReactMarkdown>{`${vacancy.flatData.content}`}</ReactMarkdown>
    </Accordion>
  )
}
