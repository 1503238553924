import { Careers } from 'src/components/Careers/Careers'
import { ContactCard } from 'src/components/ContactCard/ContactCard'
import { Layout } from 'src/components/Layout/Layout'
import { PageProps } from 'gatsby'
import React from 'react'

const Testimonials: React.FC<PageProps<{}>> = () => {
  return (
    <Layout title="Careers | RabbitPeepers">
      <Careers />
      <ContactCard />
    </Layout>
  )
}

export default Testimonials
