/** @jsx jsx */

import { css, jsx } from '@emotion/react'
import { graphql, useStaticQuery } from 'gatsby'

import { CareersItem } from 'src/components/Careers/CareersItem'
import { Container } from 'src/components/common/Container'
import { Squidex_Vacancy } from 'src/types/gatsby-types'
import { headingSecondary } from 'src/components/common/Typography'
import { mq } from 'src/utils/mq'

type Data = {
  squidex: {
    queryVacancyContents: Squidex_Vacancy[]
  }
}

const wrapperStyles = css`
  margin-top: 115px;
  margin-bottom: 80px!important;

  ${mq.md} {
    margin-bottom: 0;
    padding: 0 16px;
  }

  ${mq.lg} {
    padding: 0 24px 0 0;
  }

  ${mq.xl} {
    padding: 0 50px 0 0;
  }
`

export const Careers: React.FC = () => {
  const response = useStaticQuery<Data>(graphql`
    {
      squidex {
        queryVacancyContents {
          flatData {
            content
            location
            title
          }
          id
        }
      }
    }  
  `)

  const vacancies = response?.squidex?.queryVacancyContents

  if (!vacancies?.length) {
    throw new Error('No data for vacancies page found')
  }

  return (
    <Container css={wrapperStyles}>
      <h2 css={[headingSecondary, { textAlign: 'center' }]}>
        We are hiring!
      </h2>

      {vacancies.map((vacancy) => <CareersItem vacancy={vacancy} key={vacancy.id} />)}
    </Container>
  )
}
